<template>
    <div>
        <ts-page-title
            :title="$t('employee.pageTitle')"
            :breadcrumb="[
                { text: $t('home'), href: '/' },
                {
                    text: $t('employee.pageTitle'),
                    active: true
                }
            ]"
        />
        <ts-panel>
            <ts-panel-wrapper
                class="tw-flex tw-items-center tw-justify-between"
            >
                <div class="tw-flex">
                    <Button
                        type="info"
                        ghost
                        @click="$router.push({ name: 'employee-create' })"
                        >{{ $t("addNew") }}
                    </Button>
                    <div
                        class="tw-ml-2 tw-rounded tw-border tw-px-2 tw-whitespace-nowrap tw-mr-2 tw-flex tw-justify-items-center tw-items-center"
                    >
                        <RadioGroup v-model="side">
                            <Radio label="front">Front</Radio>
                            <Radio label="back">Back</Radio>
                        </RadioGroup>
                    </div>
                    <!-- New Preview Card -->
                    <div class="col-md-12">
                        <Dropdown
                            trigger="click"
                            class="tw-ml-2"
                            @on-click="onPreview"
                        >
                            <ts-button color="primary" :waiting="previewing">
                                {{ $t("employee.previewCard") }}
                                <Icon type="ios-arrow-down"></Icon>
                            </ts-button>
                            <template #list>
                                <DropdownMenu>
                                    <DropdownItem
                                        name="simple"
                                        :disabled="!employees.length > 0"
                                    >
                                        <i class="fas fa-print"></i>
                                        {{ $t("employee.simplePrinter") }}
                                    </DropdownItem>
                                    <DropdownItem
                                        name="pro"
                                        :disabled="!employees.length > 0"
                                    >
                                        <i class="fas fa-id-card"></i>
                                        {{ $t("employee.proPrinter") }}
                                    </DropdownItem>
                                </DropdownMenu>
                            </template>
                        </Dropdown>
                    </div>
                </div>
                <div class="tw-justify-end tw-space-x-2 tw-flex">
                    <select
                        class="form-control"
                        style="width: 200px"
                        v-model="status_id"
                    >
                        <option>
                            {{ $t("employee.all") }}
                        </option>
                        <option
                            v-for="(s, index) in status"
                            :key="index"
                            :value="s.status_id"
                        >
                            {{ s.status }}
                        </option>
                    </select>
                    <Input
                        search
                        v-model="search"
                        :placeholder="$t('employee.search')"
                        :style="width"
                        @on-focus="() => (width = 'width: 500px')"
                        @on-blur="() => (width = 'width: 300px')"
                    />
                    <ts-button
                        v-tooltip="$t('filter')"
                        @click.prevent="visible = true"
                    >
                        <i class="fa fa-filter"></i>
                    </ts-button>
                </div>
            </ts-panel-wrapper>
            <Table
                :columns="columns"
                :data="resources"
                size="small"
                :loading="loading"
                max-height="700"
                ref="selection"
                @on-select="onSelectCheck"
                @on-select-cancel="onSelectUncheck"
                @on-select-all="onSelectAllCheck"
                @on-select-all-cancel="onSelectAllUncheck"
                stripe
            >
                <template slot-scope="{ row }" slot="photo">
                    <img
                        class="tw-rounded tw-h-8"
                        v-lazy="{
                            src: photo(row),
                            error:
                                row.sex == 'Male'
                                    ? require('@/assets/staff-default-man.png')
                                    : require('@/assets/staff-default-women.png'),
                            loading: require('@/assets/Rolling-1s-200px.svg'),
                            preLoad: 1.3,
                            attempt: 1
                        }"
                    />
                </template>
                <template slot-scope="{ row }" slot="branch">
                    {{ row.branch ? row.branch.branch_name_en : "" }}
                </template>
                <template slot-scope="{ row }" slot="position">
                    {{
                        row.logical_position
                            ? row.logical_position.position_name_en
                            : ""
                    }}
                </template>
                <template slot-scope="{ row }" slot="pc_date_id">
                    {{
                        row.payroll_cycle_date
                            ? row.payroll_cycle_date.pc_date_name
                            : ""
                    }}
                </template>
                <template slot-scope="{ row }" slot="shift">
                    {{ row.shift ? row.shift.shift_name : "" }}
                </template>
                <template slot-scope="{ row }" slot="status">
                    <span class="badge bg-success" v-if="row.status_id == 1">{{
                        $t("employee.active")
                    }}</span>
                    <span class="badge bg-danger" v-if="row.status_id == 2">{{
                        $t("employee.resigned")
                    }}</span>
                    <span class="badge bg-info" v-if="row.status_id == 3">{{
                        $t("employee.terminated")
                    }}</span>
                    <span class="badge bg-warning" v-if="row.status_id == 4">{{
                        $t("employee.cancelled")
                    }}</span>
                    <span
                        class="badge bg-secondary"
                        v-if="row.status_id == 5"
                        >{{ $t("employee.other") }}</span
                    >
                </template>
                <template slot-scope="{ row }" slot="action">
                    <a
                        href="#"
                        class="text-info mr-2"
                        @click.prevent="onAddContractInfo(row)"
                        v-tooltip="$t('employee.addContractInfo')"
                    >
                        <Icon type="ios-add-circle" size="20" />
                    </a>
                    <a
                        v-if="row.guarantor"
                        href="#"
                        class="text-blue mr-2"
                        @click.prevent="onPreviewPdf(row)"
                        v-tooltip="$t('employee.previewContract')"
                    >
                        <Icon type="ios-document" size="20" />
                    </a>
                    <a
                        href="#"
                        class="text-orange mr-2"
                        @click.prevent="onMoveBrance(row)"
                        v-tooltip="$t('employee.move')"
                    >
                        <Icon type="ios-share-outline" size="20" />
                    </a>
                    <a
                        href="#"
                        class="text-primary ml-2"
                        @click.prevent="onEdit(row)"
                        v-tooltip="$t('edit')"
                    >
                        <Icon type="ios-create" size="20" />
                    </a>
                    <Poptip
                        confirm
                        :title="$t('areYouSure')"
                        @on-ok="onDelete(row)"
                        :transfer="true"
                        :ok-text="$t('yes')"
                        :cancel-text="$t('no')"
                    >
                        <a
                            class="ml-2 text-danger"
                            v-tooltip="$t('delete')"
                            :disabled="row._deleting"
                        >
                            <i
                                class="fas fa-circle-notch fa-spin"
                                v-if="row._deleting"
                            ></i>
                            <Icon type="ios-trash" size="20" v-else />
                        </a>
                    </Poptip>
                </template>
            </Table>
            <div class="tw-flex tw-items-center tw-justify-between tw-p-4">
                <div>
                    <ts-button
                        color="warning"
                        outline
                        class="tw-mr-2 btn-sm"
                        :disabled="
                            resources.length <= 0 || employees.length == 0
                        "
                        @click="moveBranch"
                    >
                        <Icon type="ios-swap" size="15" />
                        {{ $t("employee.moveBranch") }} ({{ employees.length }})
                        {{ $t("employee.profile") }}</ts-button
                    >
                </div>
                <ts-i-pagination
                    v-model="pagination"
                    @navigate="fetchData"
                ></ts-i-pagination>
            </div>
        </ts-panel>
        <Drawer
            :title="$t('filter')"
            :closable="false"
            :value="visible"
            @on-close="() => (visible = false)"
            width="300px"
        >
            <div class="mb-3">
                <label class="form-col-label control-label">{{
                    $t("branchName")
                }}</label>
                <Select
                    v-model="branch_id"
                    class="tw-w-full"
                    filterable
                    multiple
                    :max-tag-count="1"
                >
                    <Option
                        v-for="branch in branchs"
                        :value="branch.value"
                        :key="branch.value"
                    >
                        {{ branch.label }}
                    </Option>
                </Select>
                <!-- <ts-branch-filter @filter="value => (branch_id = value)" /> -->
            </div>
        </Drawer>
        <!-- form-action -->
        <Modal
            v-model="showForm"
            draggable
            sticky
            scrollable
            :mask="true"
            :footer-hide="true"
            :z-index="1022"
            :title="$t('employee.moveBranch')"
            width="500"
        >
            <move-branch
                ref="move_branch"
                @fetchData="fetchData"
                :employees="employees"
                @cancel="cancel"
            />
        </Modal>
        <!-- form-add-contract-info -->
        <Modal
            v-model="showAddContractInfo"
            draggable
            sticky
            scrollable
            :mask="true"
            :footer-hide="true"
            :z-index="1022"
            :title="employee_name"
            width="900px"
        >
            <add-contract-info
                ref="add_contract_info"
                @fetchData="fetchData"
                :employees="employees"
                @cancel="cancel"
            />
        </Modal>
        <pdf-preview
            v-model="pdf_view"
            :id-lists="employee_id_lists"
            @cancel="value => close(value)"
        />
        <Modal
            v-model="show_expired_date"
            draggable
            sticky
            scrollable
            :mask="true"
            :z-index="1022"
            :title="$t('employee.expiredDate')"
            width="300"
            @on-ok="preview"
        >
            <DatePicker
                v-model="modelPreview.expired_date"
                style="width: 100%"
                :transfer="true"
                format="dd-MM-yyyy"
                @on-change="onChangeExpiredDate"
            ></DatePicker>
            <Spin size="large" fix v-if="loading_expired_date"> </Spin>
        </Modal>
        <Modal
            v-model="showPreviewing"
            draggable
            sticky
            :mask="true"
            :footer-hide="true"
            :z-index="1022"
            :title="$t('pdf')"
            width="1200px"
            class-name="vertical-center-modal"
        >
            <ts-preview-pdf v-model="previewing" :src="src" />
        </Modal>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapState, mapActions } from "vuex";
import { debounce, isEmpty } from "lodash";
import moveBranch from "./components/move-branch";
import PdfPreview from "./components/pdf-preview";
import addContractInfo from "./components/add-contract-info";
import moment from "moment";

export default {
    name: "employeeIndex",
    components: {
        moveBranch,
        PdfPreview,
        addContractInfo
    },
    data() {
        return {
            errors: new Errors(),
            loading: false,
            previewing: false,
            showPreviewing: false,
            loading_expired_date: false,
            width: "width: 300px",
            visible: false,
            showForm: false,
            show_expired_date: false,
            showAddContractInfo: false,
            pdf_view: false,
            side: "front",
            // branch_id: [],
            employees: [],
            branchs: [],
            employee_id_lists: [],
            employee_name: null,
            src: "",
            modelPreview: {
                type: "simple",
                expired_date: moment().format("DD-MM-YYYY")
            }
        };
    },
    computed: {
        ...mapState("humanResource/employee", [
            "resources",
            "pagination",
            "status"
        ]),
        search: {
            get() {
                return this.$store.state.humanResource.employee.search;
            },
            set(newValue) {
                this.$store.commit(
                    "humanResource/employee/SET_SEARCH",
                    newValue
                );
                this.$store.commit("humanResource/employee/RESET_CURRENT_PAGE");
            }
        },
        status_id: {
            get() {
                return this.$store.state.humanResource.employee.status_id;
            },
            set(newValue) {
                this.$store.commit(
                    "humanResource/employee/SET_STATUS_ID",
                    newValue
                );
            }
        },
        branch_id: {
            get() {
                return this.$store.state.humanResource.employee.branch_id;
            },
            set(newValue) {
                this.$store.commit(
                    "humanResource/employee/SET_BRANCH_ID",
                    newValue
                );
            }
        },
        columns() {
            return [
                {
                    type: "selection",
                    width: 60,
                    align: "center"
                },
                {
                    title: this.$t("employee.branch"),
                    slot: "branch",
                    minWidth: 150
                },
                {
                    title: this.$t("employee.photo"),
                    slot: "photo",
                    minWidth: 80
                },
                {
                    title: this.$t("employee.cardNumber"),
                    key: "card_id",
                    minWidth: 100,
                    align: "center",
                    sortable: true
                },
                {
                    title: this.$t("employee.nameKh"),
                    key: "employee_name_kh",
                    minWidth: 170,
                    sortable: true
                },
                {
                    title: this.$t("employee.nameEn"),
                    key: "employee_name_en",
                    minWidth: 170,
                    sortable: true
                },
                {
                    title: this.$t("employee.sex"),
                    key: "sex",
                    minWidth: 90,
                    align: "center"
                },
                {
                    title: this.$t("employee.position"),
                    slot: "position",
                    minWidth: 200,
                    sortable: true
                },
                {
                    title: this.$t("employee.hiredDate"),
                    key: "hire_date",
                    minWidth: 150,
                    align: "center",
                    sortable: true
                },
                {
                    title: this.$t("employee.status"),
                    slot: "status",
                    minWidth: 100,
                    align: "center"
                },
                {
                    title: this.$t("employee.effectiveDate"),
                    key: "status_effective_date",
                    minWidth: 150,
                    align: "center",
                    sortable: true
                },
                {
                    title: this.$t("employee.phone"),
                    key: "phone",
                    minWidth: 150,
                    align: "right",
                    sortable: true
                },
                {
                    title: this.$t("employee.shift"),
                    slot: "shift",
                    minWidth: 150,
                    sortable: true
                },
                {
                    title: this.$t("employee.payrollCycleDate"),
                    slot: "pc_date_id",
                    minWidth: 200,
                    sortable: true
                },
                {
                    title: this.$t("actions"),
                    key: "action",
                    slot: "action",
                    fixed: "right",
                    align: "center",
                    width: 150
                }
            ];
        }
    },
    async created() {
        this.loading = true;
        await this.fetchResource();
        this.fetchData();
        this.loading = false;
    },
    methods: {
        ...mapActions("humanResource/employee", ["getStatus"]),
        onChangeExpiredDate(date) {
            this.modelPreview.expired_date = date;
        },
        async onPreview(type) {
            this.show_expired_date = true;
            this.loading_expired_date = true;
            let employee_id = this.employees
                .map(employee => employee.employee_id)
                .filter(Boolean);
            await this.$store
                .dispatch("humanResource/employee/expiredDateCard", {
                    staff_id_list: employee_id
                })
                .then(response => {
                    this.modelPreview.expired_date = response.data[0]
                        .expired_date_card
                        ? moment(
                              response.data[0].expired_date_card,
                              "DD-MM-YYYY"
                          ).format("DD-MM-YYYY")
                        : moment().format("DD-MM-YYYY");
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading_expired_date = false;
                });
            this.modelPreview.type = type;
        },
        preview() {
            this.errors = new Errors();
            this.showPreviewing = true;
            this.previewing = true;
            let employee_id = this.employees
                .map(employee => employee.employee_id)
                .filter(Boolean);
            this.src = "";
            this.$store
                .dispatch("humanResource/employee/printCard", {
                    staff_id_list: employee_id,
                    side: this.side,
                    type: this.modelPreview.type,
                    expired_date: this.modelPreview.expired_date
                })
                .then(response => {
                    this.src = response.url;
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                    this.errors = new Errors(error.errors);
                })
                .finally(() => {
                    this.previewing = false;
                });
        },
        photo(record) {
            if (record.photo) {
                return record.photo;
            }

            return record.sex == "Male"
                ? require("@/assets/staff-default-man.png")
                : require("@/assets/staff-default-women.png");
        },
        fetchData(attributes) {
            this.loading = true;
            this.$store
                .dispatch(
                    "humanResource/employee/fetch",
                    Object.assign(
                        {
                            page: this.pagination.currentPage,
                            status_id: this.status_id,
                            search: this.search,
                            branch_id: this.branch_id
                        },
                        attributes
                    )
                )
                .catch(error => {
                    this.loading = false;
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        async fetchResource() {
            await this.getStatus();
            this.$store
                .dispatch("auth/user/getBranchAssignedForFilter")
                .then(response => {
                    this.branchs = response.data.map(el => ({
                        value: el.branch_id,
                        label: el.branch_name_en
                    }));
                    if (!this.branch_id) {
                        this.branch_id = [
                            this.$store.state.authUser.user.default_branch
                                .branch_id
                        ];
                    }
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                });
        },
        onEdit(record) {
            this.$store.commit("humanResource/employee/SET_EDIT_DATA", record);
            this.$router.push({
                name: "employee-edit",
                params: { id: record.employee_id }
            });
        },
        onSelectCheck(record) {
            this.setEmployeeIdListSelected(record);
        },
        onSelectUncheck(record) {
            this.setEmployeeIdListSelected(record);
        },
        onSelectAllCheck(record) {
            this.setEmployeeIdListSelected(record);
        },
        onSelectAllUncheck(record) {
            this.setEmployeeIdListSelected(record);
        },
        handleSelectAll(status) {
            this.$refs.selection.selectAll(status);
        },
        setEmployeeIdListSelected(records) {
            this.employees = [];
            if (records.length > 0) {
                records.map(row => {
                    this.employees.push({
                        employee_id: row.employee_id,
                        employee_name_en: row.employee_name_en,
                        card_id: row.card_id,
                        sex: row.sex,
                        photo: row.photo
                    });
                });
            }
        },
        onMoveBrance(record) {
            this.$store.commit("humanResource/employee/SET_EDIT_DATA", record);
            this.handleSelectAll(false);
            this.employees = [];
            this.employees.push({
                employee_id: record.employee_id,
                employee_name_en: record.employee_name_en,
                card_id: record.card_id,
                sex: record.sex,
                photo: record.photo,
                branch_id: record.branch_id
            });
            this.$refs.move_branch.fetchResource();
            this.showForm = true;
        },
        moveBranch() {
            this.$refs.move_branch.fetchResource();
            this.showForm = true;
        },
        cancel() {
            this.fetchData();
            this.employees = [];
            this.showForm = false;
            this.showAddContractInfo = false;
            this.handleSelectAll(false);
        },
        photoEmp(record) {
            if (record.photo) {
                return (
                    record.photo +
                    "&api_token=" +
                    this.$store.getters.accessToken
                );
            }

            return record.sex == "Male" ? this.manPhoto : this.womenPhoto;
        },
        async onDelete(record) {
            record._deleting = true;
            this.$store
                .dispatch("humanResource/employee/destroy", record.employee_id)
                .then(response => {
                    this.fetchData();
                    record._deleting = false;
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                })
                .catch(error => {
                    this.notice({ type: "error", text: error.message });
                    record._deleting = false;
                });
        },
        onPreviewPdf(record) {
            this.employee_id_lists = [];
            this.employee_id_lists.push(record.employee_id);
            this.pdf_view = true;
        },
        close(value) {
            this.pdf_view = value;
            this.employee_id_lists = [];
        },
        onAddContractInfo(record) {
            this.employee_name =
                record.employee_name_en + " | " + record.employee_name_kh;
            this.$store.commit("humanResource/employee/SET_EDIT_DATA", record);
            this.$refs.add_contract_info.fetchResource();
            this.showAddContractInfo = true;
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "EMPLOYEE",
                desc: not.text
            });
        }
    },
    watch: {
        search: debounce(function() {
            this.fetchData();
        }, 500),
        branch_id: debounce(function() {
            this.fetchData();
        }, 500),
        status_id: debounce(function() {
            this.fetchData();
        }, 500)
    },
    // beforeRouteEnter (to, from, next) {
    //     next(vm => {
    //         vm.fetchData()
    //     })
    // },
    beforeRouteLeave(to, from, next) {
        if (isEmpty(to.params) && to.name != "employee-create") {
            this.$store.commit("humanResource/employee/RESET_STATE");
        }
        next();
    }
};
</script>
<style scoped>
.text-nowrap {
    white-space: nowrap;
}
.vertical-center-modal {
    display: flex;
    align-items: center;
    justify-content: center;
}
.vertical-center-modal .ivu-modal {
    top: 0;
}
</style>
