var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form',{staticClass:"form-horizontal"},[_c('h5',{staticClass:"section-divider"},[_vm._v(" "+_vm._s(_vm.$t("employee.employment"))+" ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("employee.salaryLevel"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"tw-flex tw-items-center tw-justify-between"},[_c('div',{staticClass:"tw-justify-start"},[_c('select',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.model.level_id),expression:"model.level_id",modifiers:{"number":true}}],staticClass:"form-select",class:{
                                        'is-invalid': _vm.errors.has('level_id')
                                    },on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return _vm._n(val)}); _vm.$set(_vm.model, "level_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function () { return (_vm.model.salary_level_id = null); }]}},[_c('option',{domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t("select")))]),_vm._l((_vm.levels),function(value,index){return _c('option',{key:index,domProps:{"value":value.id}},[_vm._v(" "+_vm._s(value.name)+" ")])})],2),(_vm.errors.has('level_id'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("level_id"))+" ")]):_vm._e()]),_c('div',{staticClass:"tw-justify-end"},[_c('select',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.model.salary_level_id),expression:"model.salary_level_id",modifiers:{"number":true}}],staticClass:"form-select",class:{
                                        'is-invalid': _vm.errors.has(
                                            'salary_level_id'
                                        )
                                    },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return _vm._n(val)}); _vm.$set(_vm.model, "salary_level_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t("select")))]),_vm._l((_vm.salaryLevels),function(value,index){return _c('option',{key:index,domProps:{"value":value.id}},[_vm._v(" "+_vm._s(value.name)+" ")])})],2),(_vm.errors.has('salary_level_id'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("salary_level_id"))+" ")]):_vm._e()])])])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("employee.age"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.model.age),expression:"model.age",modifiers:{"number":true}}],staticClass:"form-control",class:{
                                'is-invalid': _vm.errors.has('age')
                            },attrs:{"type":"number"},domProps:{"value":(_vm.model.age)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "age", _vm._n($event.target.value))},"blur":function($event){return _vm.$forceUpdate()}}})])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("employee.marritalStatus"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('select',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.model.marrital_status),expression:"model.marrital_status",modifiers:{"number":true}}],staticClass:"form-select",class:{
                                'is-invalid': _vm.errors.has('marrital_status')
                            },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return _vm._n(val)}); _vm.$set(_vm.model, "marrital_status", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t("select")))]),_vm._l((_vm.marritalStatus),function(value,index){return _c('option',{key:index,domProps:{"value":value}},[_vm._v(" "+_vm._s(value)+" ")])})],2),(_vm.errors.has('marrital_status'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("marrital_status"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-md-4 required"},[_vm._v(" "+_vm._s(_vm.$t("employee.employerName"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.model.employer_name),expression:"model.employer_name",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                                'is-invalid': _vm.errors.has('employer_name')
                            },attrs:{"type":"text"},domProps:{"value":(_vm.model.employer_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "employer_name", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.errors.has('employer_name'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("employer_name"))+" ")]):_vm._e()])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("employee.province"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.province_code),expression:"model.province_code"}],staticClass:"form-control",class:{
                                'is-invalid': _vm.errors.has('province_code')
                            },on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "province_code", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.provinceChange(_vm.model.province_code)}],"input":function($event){_vm.model.district_code = _vm.model.commune_code = _vm.model.village_code = null}}},[_c('option',{staticStyle:{"display":"none"},attrs:{"disabled":"","selected":""},domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t("select")))]),_vm._l((_vm.provinces),function(province,index){return _c('option',{key:index,domProps:{"value":province.province_code}},[_vm._v(" "+_vm._s(province.name_en)+" | "+_vm._s(province.name_kh)+" ")])})],2),(_vm.errors.has('province_code'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("province_code"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("employee.district"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('ts-loading-banner',{attrs:{"loading":_vm.province_change}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.district_code),expression:"model.district_code"}],staticClass:"form-control",class:{
                                    'is-invalid': _vm.errors.has(
                                        'district_code'
                                    )
                                },on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "district_code", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.districtChange(_vm.model.district_code)}],"input":function($event){_vm.model.commune_code = _vm.model.village_code = null}}},[_c('option',{staticStyle:{"display":"none"},attrs:{"disabled":"","selected":""},domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t("select")))]),_vm._l((_vm.districts),function(district,index){return _c('option',{key:index,domProps:{"value":district.district_code}},[_vm._v(" "+_vm._s(district.name_en)+" | "+_vm._s(district.name_kh)+" ")])})],2)]),(_vm.errors.has('district_code'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("district_code"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("employee.commune"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('ts-loading-banner',{attrs:{"loading":_vm.district_change}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.commune_code),expression:"model.commune_code"}],staticClass:"form-control",class:{
                                    'is-invalid': _vm.errors.has('commune_code')
                                },on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "commune_code", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.communeChange(_vm.model.commune_code)}],"input":function($event){_vm.model.village_code = null}}},[_c('option',{staticStyle:{"display":"none"},attrs:{"disabled":"","selected":""},domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t("select")))]),_vm._l((_vm.communes),function(commune,index){return _c('option',{key:index,domProps:{"value":commune.commune_code}},[_vm._v(" "+_vm._s(commune.name_en)+" | "+_vm._s(commune.name_kh)+" ")])})],2)]),(_vm.errors.has('commune_code'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("commune_code"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("employee.village"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('ts-loading-banner',{attrs:{"loading":_vm.commune_change}},[_c('div',{staticClass:"input-group"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.village_code),expression:"model.village_code"}],staticClass:"form-control",class:{
                                        'is-invalid': _vm.errors.has(
                                            'village_code'
                                        )
                                    },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "village_code", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{staticStyle:{"display":"none"},attrs:{"disabled":"","selected":""},domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t("select")))]),_vm._l((_vm.villages),function(village,index){return _c('option',{key:index,domProps:{"value":village.village_code}},[_vm._v(" "+_vm._s(village.name_en)+" | "+_vm._s(village.name_kh)+" ")])})],2),(
                                        !_vm.villageSaving && _vm.model.commune_code
                                    )?_c('Poptip',{attrs:{"title":_vm.$t('employee.village'),"transfer":true,"width":"240"},on:{"on-popper-show":function () { return (_vm.name_en = _vm.name_kh = ''); }}},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('add')),expression:"$t('add')"}],staticClass:"btn btn-info",attrs:{"disabled":_vm.villageSaving}},[(_vm.villageSaving)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_c('i',{staticClass:"fas fa-plus"})]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"mb-2"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t("employee.nameEn")))]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.name_en),expression:"name_en",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                                                    'is-invalid': _vm.errors.has(
                                                        'name_en'
                                                    )
                                                },attrs:{"type":"text"},domProps:{"value":(_vm.name_en)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name_en=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.errors.has('name_en'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("name_en"))+" ")]):_vm._e()]),_c('div',{staticClass:"mb-2"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t("employee.nameKh")))]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.name_kh),expression:"name_kh",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                                                    'is-invalid': _vm.errors.has(
                                                        'name_kh'
                                                    )
                                                },attrs:{"type":"text"},domProps:{"value":(_vm.name_kh)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name_kh=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.errors.has('name_kh'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("name_kh"))+" ")]):_vm._e()]),_c('div',{staticClass:"tw-mt-2 tw-text-right"},[_c('a',{staticClass:"ivu-btn ivu-btn-primary ivu-btn-small",on:{"click":function($event){$event.preventDefault();return _vm.onSaveVillage.apply(null, arguments)}}},[_c('span',[_vm._v("Save")])])])])]):_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('add')),expression:"$t('add')"}],staticClass:"btn btn-default",attrs:{"disabled":!_vm.model.commune_code}},[(_vm.villageSaving)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_c('i',{staticClass:"fas fa-plus"})])],1)]),(_vm.errors.has('village_code'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("village_code"))+" ")]):_vm._e()],1)])])]),_c('h5',{staticClass:"section-divider"},[_vm._v(" "+_vm._s(_vm.$t("employee.employeeGuarantor"))+" ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-md-4 required"},[_vm._v(" "+_vm._s(_vm.$t("employee.guarantorName"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.model.guarantor_name),expression:"model.guarantor_name",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                                'is-invalid': _vm.errors.has('guarantor_name')
                            },attrs:{"type":"text"},domProps:{"value":(_vm.model.guarantor_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "guarantor_name", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.errors.has('guarantor_name'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("guarantor_name"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row mb-2"},[_c('label',{staticClass:"col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("employee.sex"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"tw-flex"},[_c('div',{staticClass:"tw-justify-start"},[_c('div',{staticClass:"form-check form-check-inline"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.guarantor_sex),expression:"model.guarantor_sex"}],staticClass:"form-check-input",attrs:{"type":"radio","id":"male","value":"Male"},domProps:{"checked":_vm._q(_vm.model.guarantor_sex,"Male")},on:{"change":function($event){return _vm.$set(_vm.model, "guarantor_sex", "Male")}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"male"}},[_vm._v("Male")])]),_c('div',{staticClass:"form-check form-check-inline"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.guarantor_sex),expression:"model.guarantor_sex"}],staticClass:"form-check-input",attrs:{"type":"radio","id":"female","value":"Female"},domProps:{"checked":_vm._q(_vm.model.guarantor_sex,"Female")},on:{"change":function($event){return _vm.$set(_vm.model, "guarantor_sex", "Female")}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"female"}},[_vm._v("Female")])])]),_c('div',{staticClass:"tw-justify-end"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.model.guarantor_age),expression:"model.guarantor_age",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                                        'is-invalid': _vm.errors.has(
                                            'guarantor_age'
                                        )
                                    },attrs:{"placeholder":_vm.$t('employee.guarantorAge'),"type":"number"},domProps:{"value":(_vm.model.guarantor_age)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "guarantor_age", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})])]),(_vm.errors.has('guarantor_sex'))?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors.first("guarantor_sex"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("employee.ssnNo"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.model.guarantor_nid_number),expression:"model.guarantor_nid_number",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                                'is-invalid': _vm.errors.has(
                                    'guarantor_nid_number'
                                )
                            },attrs:{"type":"text"},domProps:{"value":(_vm.model.guarantor_nid_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "guarantor_nid_number", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.errors.has('guarantor_nid_number'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("guarantor_nid_number"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("employee.nationality"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('select',{directives:[{name:"model",rawName:"v-model.number",value:(_vm.model.nationality_id),expression:"model.nationality_id",modifiers:{"number":true}}],staticClass:"form-control",class:{
                                'is-invalid': _vm.errors.has('nationality_id')
                            },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return _vm._n(val)}); _vm.$set(_vm.model, "nationality_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t("select")))]),_vm._l((_vm.nationalities),function(nationality,index){return _c('option',{key:index,domProps:{"value":nationality.nationality_id}},[_vm._v(_vm._s(nationality.nationality_name_en))])})],2),(_vm.errors.has('nationality_id'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("nationality_id"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("employee.spouseName"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.model.spouse_name),expression:"model.spouse_name",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                                'is-invalid': _vm.errors.has('spouse_name')
                            },attrs:{"type":"text"},domProps:{"value":(_vm.model.spouse_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "spouse_name", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.errors.has('spouse_name'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("spouse_name"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row mb-2"},[_c('label',{staticClass:"col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("employee.sex"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('div',{staticClass:"tw-flex"},[_c('div',{staticClass:"tw-justify-start"},[_c('div',{staticClass:"form-check form-check-inline"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.spouse_sex),expression:"model.spouse_sex"}],staticClass:"form-check-input",attrs:{"type":"radio","id":"spouse_male","value":"Male"},domProps:{"checked":_vm._q(_vm.model.spouse_sex,"Male")},on:{"change":function($event){return _vm.$set(_vm.model, "spouse_sex", "Male")}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"spouse_male"}},[_vm._v("Male")])]),_c('div',{staticClass:"form-check form-check-inline"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.spouse_sex),expression:"model.spouse_sex"}],staticClass:"form-check-input",attrs:{"type":"radio","id":"spouse_female","value":"Female"},domProps:{"checked":_vm._q(_vm.model.spouse_sex,"Female")},on:{"change":function($event){return _vm.$set(_vm.model, "spouse_sex", "Female")}}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"spouse_female"}},[_vm._v("Female")])])]),_c('div',{staticClass:"tw-justify-end"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.model.spouse_age),expression:"model.spouse_age",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                                        'is-invalid': _vm.errors.has(
                                            'spouse_age'
                                        )
                                    },attrs:{"placeholder":_vm.$t('employee.spouseAge'),"type":"number"},domProps:{"value":(_vm.model.spouse_age)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "spouse_age", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}})])]),(_vm.errors.has('spouse_sex'))?_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.errors.first("spouse_sex"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("employee.ssnNo"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.model.spouse_nid_number),expression:"model.spouse_nid_number",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                                'is-invalid': _vm.errors.has(
                                    'spouse_nid_number'
                                )
                            },attrs:{"type":"text"},domProps:{"value":(_vm.model.spouse_nid_number)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "spouse_nid_number", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.errors.has('spouse_nid_number'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("spouse_nid_number"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("employee.relationship"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.model.relationship),expression:"model.relationship",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                                'is-invalid': _vm.errors.has('relationship')
                            },attrs:{"type":"text"},domProps:{"value":(_vm.model.relationship)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "relationship", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.errors.has('relationship'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("relationship"))+" ")]):_vm._e()])])]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("employee.province"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.grt_province_code),expression:"model.grt_province_code"}],staticClass:"form-control",class:{
                                'is-invalid': _vm.errors.has(
                                    'grt_province_code'
                                )
                            },on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "grt_province_code", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.grtProvinceChange(_vm.model.grt_province_code)}],"input":function($event){_vm.model.grt_district_code = _vm.model.grt_commune_code = _vm.model.grt_village_code = null}}},[_c('option',{staticStyle:{"display":"none"},attrs:{"disabled":"","selected":""},domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t("select")))]),_vm._l((_vm.provinces),function(province,index){return _c('option',{key:index,domProps:{"value":province.province_code}},[_vm._v(" "+_vm._s(province.name_en)+" | "+_vm._s(province.name_kh)+" ")])})],2),(_vm.errors.has('grt_province_code'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("grt_province_code"))+" ")]):_vm._e()])]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("employee.district"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('ts-loading-banner',{attrs:{"loading":_vm.grt_province_change}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.grt_district_code),expression:"model.grt_district_code"}],staticClass:"form-control",class:{
                                    'is-invalid': _vm.errors.has(
                                        'grt_district_code'
                                    )
                                },on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "grt_district_code", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.grtDistrictChange(
                                        _vm.model.grt_district_code
                                    )}],"input":function($event){_vm.model.grt_commune_code = _vm.model.grt_village_code = null}}},[_c('option',{staticStyle:{"display":"none"},attrs:{"disabled":"","selected":""},domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t("select")))]),_vm._l((_vm.grt_districts),function(district,index){return _c('option',{key:index,domProps:{"value":district.district_code}},[_vm._v(" "+_vm._s(district.name_en)+" | "+_vm._s(district.name_kh)+" ")])})],2)]),(_vm.errors.has('grt_district_code'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("grt_district_code"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("employee.commune"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('ts-loading-banner',{attrs:{"loading":_vm.grt_district_change}},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.grt_commune_code),expression:"model.grt_commune_code"}],staticClass:"form-control",class:{
                                    'is-invalid': _vm.errors.has(
                                        'grt_commune_code'
                                    )
                                },on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "grt_commune_code", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.grtCommuneChange(_vm.model.grt_commune_code)}],"input":function($event){_vm.model.grt_village_code = null}}},[_c('option',{staticStyle:{"display":"none"},attrs:{"disabled":"","selected":""},domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t("select")))]),_vm._l((_vm.grt_communes),function(commune,index){return _c('option',{key:index,domProps:{"value":commune.commune_code}},[_vm._v(" "+_vm._s(commune.name_en)+" | "+_vm._s(commune.name_kh)+" ")])})],2)]),(_vm.errors.has('grt_commune_code'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("grt_commune_code"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("employee.village"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('ts-loading-banner',{attrs:{"loading":_vm.grt_commune_change}},[_c('div',{staticClass:"input-group"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.grt_village_code),expression:"model.grt_village_code"}],staticClass:"form-control",class:{
                                        'is-invalid': _vm.errors.has(
                                            'grt_village_code'
                                        )
                                    },on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "grt_village_code", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{staticStyle:{"display":"none"},attrs:{"disabled":"","selected":""},domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t("select")))]),_vm._l((_vm.grt_villages),function(village,index){return _c('option',{key:index,domProps:{"value":village.village_code}},[_vm._v(" "+_vm._s(village.name_en)+" | "+_vm._s(village.name_kh)+" ")])})],2),(
                                        !_vm.villageSaving && _vm.model.commune_code
                                    )?_c('Poptip',{attrs:{"title":_vm.$t('employee.village'),"transfer":true,"width":"240"},on:{"on-popper-show":function () { return (_vm.name_en = _vm.name_kh = ''); }}},[_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('add')),expression:"$t('add')"}],staticClass:"btn btn-info",attrs:{"disabled":_vm.villageSaving}},[(_vm.villageSaving)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_c('i',{staticClass:"fas fa-plus"})]),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',{staticClass:"mb-2"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t("employee.nameEn")))]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.name_en),expression:"name_en",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                                                    'is-invalid': _vm.errors.has(
                                                        'name_en'
                                                    )
                                                },attrs:{"type":"text"},domProps:{"value":(_vm.name_en)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name_en=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.errors.has('name_en'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("name_en"))+" ")]):_vm._e()]),_c('div',{staticClass:"mb-2"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t("employee.nameKh")))]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.name_kh),expression:"name_kh",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                                                    'is-invalid': _vm.errors.has(
                                                        'name_kh'
                                                    )
                                                },attrs:{"type":"text"},domProps:{"value":(_vm.name_kh)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name_kh=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.errors.has('name_kh'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("name_kh"))+" ")]):_vm._e()]),_c('div',{staticClass:"tw-mt-2 tw-text-right"},[_c('a',{staticClass:"ivu-btn ivu-btn-primary ivu-btn-small",on:{"click":function($event){$event.preventDefault();return _vm.onSaveVillage.apply(null, arguments)}}},[_c('span',[_vm._v("Save")])])])])]):_c('a',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('add')),expression:"$t('add')"}],staticClass:"btn btn-default",attrs:{"disabled":!_vm.model.commune_code}},[(_vm.villageSaving)?_c('i',{staticClass:"fas fa-circle-notch fa-spin"}):_c('i',{staticClass:"fas fa-plus"})])],1)]),(_vm.errors.has('grt_village_code'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("grt_village_code"))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"row mb-3"},[_c('label',{staticClass:"col-md-4"},[_vm._v(" "+_vm._s(_vm.$t("employee.houseNo"))+" ")]),_c('div',{staticClass:"col-md-8"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.model.grt_house_no),expression:"model.grt_house_no",modifiers:{"trim":true}}],staticClass:"form-control",class:{
                                'is-invalid': _vm.errors.has('grt_house_no')
                            },attrs:{"type":"text"},domProps:{"value":(_vm.model.grt_house_no)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "grt_house_no", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.errors.has('grt_house_no'))?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(_vm.errors.first("grt_house_no"))+" ")]):_vm._e()])])])]),_c('div',{staticClass:"tw-flex tw-justify-end tw-space-x-2 tw-sh"},[_c('ts-button',{staticClass:"btn-gray",on:{"click":function($event){$event.preventDefault();return (function () { return _vm.$emit('cancel'); }).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("cancel")))]),_c('ts-button',{attrs:{"color":"info","outline":"","waiting":_vm.save_previewing,"disabled":_vm.save_previewing || _vm.waiting},on:{"click":function($event){$event.preventDefault();return _vm.onSavePreview.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("employee.savePreviewContract")))]),_c('ts-button',{staticClass:"btn btn-primary d-block",attrs:{"color":"primary","waiting":_vm.waiting,"disabled":_vm.waiting || _vm.save_previewing},on:{"click":function($event){$event.preventDefault();return _vm.onSave.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("save")))])],1),(_vm.loading)?_c('Spin',{attrs:{"size":"large","fix":""}}):_vm._e()],1),_c('Modal',{attrs:{"draggable":"","sticky":"","scrollable":"","mask":false,"footer-hide":true,"fullscreen":"","z-index":1022,"title":_vm.$t('previewPdf')},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',{staticClass:"demo-spin-article"},[_c('div',{attrs:{"id":"container"}},[_c('ts-preview-pdf',{attrs:{"src":_vm.src}})],1)]),(_vm.save_previewing)?_c('Spin',{attrs:{"size":"large","fix":""}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }